* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.in {
  border-radius: 4px;
}

.big-media {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 6px;
  width: 250px;
  height: 262px;
}
.editProductImgs {
  width: 250px;
  border: 5px solid black;
}
.editProductMainImage {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  margin-bottom: 3px;
  overflow: hidden;
}
.third-section div {
  width: 220px;
  height: 150px;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  color: white;
}

.third-section {
  margin: auto;
  justify-content: space-around;
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
}

button {
  background: none;
  border: none;
  padding: 3px;
}
.but {
  border: 2px solid #d1d1d1;
  width: auto;
  height: 130px;
  border-style: dotted;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
}
.small-box {
  border: 1px solid black;
  width: 100px;
  height: 80px;
  border-radius: 4px;
}
.optionValuesStyle {
  background-color: #d1d1d1 !important;
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
  padding: 5px;
  text-align: center !important;
}
.btn-img {
  background-color: skyblue;
  border: transparent;
  padding: 3px;
  color: blue;
}
.main-div1 {
  display: flex;
  justify-content: space-between;
}

.div-child {
  margin-top: 10px;
  display: flex;
}

.div-child span {
  margin-left: 10px;
}

.col-child1 .box {
  border: 1px solid black;
  width: 70px;
  height: 70px;
}

.mediaAlign {
  border: 1px dashed rgb(185, 185, 245) !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mediaAlign:hover {
  background-color: #f8f8f8;
  border: 1px dashed rgb(8, 8, 151) !important;
}
.fileinputAlign {
  cursor: pointer;
  font-weight: bold;
  padding: 4px 8px;
  background: #e7f2f8;
  border-radius: 5px;
  color: rgb(95, 130, 175);
}

.image-item {
  border: 1px solid #d7d7d7;
  width: 150px;
  height: 150px;
  border-radius: 6px;
  padding: 3px;
  margin: 4px 4px;
  overflow: auto;
  position: relative;
  cursor: pointer;
}
.image-item:hover {
  border: 1px solid rgb(153 144 144);
  border-radius: 6px;
}

.imgDiv {
  position: relative;
}
.editProductImage {
  width: 100%;
}
.imgBox {
  display: none;
  position: absolute;
  top: 7px;
  left: 30px;
}
.image-item:hover .imgBox {
  display: block;
  z-index: 1;
}

.image {
  width: 100%;
  height: 100%;
}
.image-item:hover .image {
  filter: brightness(50%);
  width: 100%;
}

.image-item .imgDiv .imgBoxSelected {
  display: block;
  position: absolute;
  top: 7px;
  left: 30px;
  z-index: 1;
}
.imageSelected {
  opacity: 0.5;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.outerBox {
  width: 19px;
  height: 19px;
  border-radius: 3px;
  background-color: #336eff;
  display: flex;
  padding: 1px;
  align-items: center;
}
.innerBox {
  width: 13px;
  margin-left: 1.9px;
  height: 2px;
  background-color: white;
  margin-bottom: 0.5px;
  border-radius: 2px;
  bottom: 2px;
}
.set-main {
  display: flex;
  flex-wrap: wrap;
}
.list {
  display: flex;
  justify-content: space-between;
  list-style: none;
}
.optionTable th,
tbody {
  border: none !important;
}
.varient-image {
  width: 70px;
  height: 70px;
}
.changeButton {
  border-radius: 4px !important;
}
.variantCheck {
  border: 1px solid #bbbfc4;
  border-radius: 6px;
  width: 170px;
  height: 38px;
}
.variantCheck:hover {
  background-color: #f7f7f7;
}
.custom-bg {
  background: #e7f2f8;
}
.card-bg {
  background: #eaeaea;
}
.container-bg {
  background-color: #f8f8f8;
}
.hoverPoint {
  cursor: pointer;
}
.hoverPoint .material-icons {
  color: #336eff;
}
.hoverPoint:hover {
  background: #e7f2f8;
}
.customScroll::-webkit-scrollbar {
  height: 9px;
  width: 5px;
}
.customScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.customScroll::-webkit-scrollbar-thumb {
  background: rgb(172, 172, 172);
  border-radius: 10px;
}
.customScroll::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 105, 105);
}
